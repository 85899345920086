import { fetchMapboxLocationLngLat } from '../helpers/api-mapbox';
import { fetchAllLocations } from '../helpers/api-locations';
import { distanceSorter } from '../helpers/distance';

class MrLocationsByPostalCode extends HTMLElement {

	#submitHandler = ( e: SubmitEvent ): void => {
		const postalCodeInput = this.querySelector<HTMLInputElement>( 'input[data-postal-code-input]' );
		if ( !postalCodeInput ) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		this.updateLocationIntroTitle( postalCodeInput.value );
		this.renderLocationsList( postalCodeInput.value );
	};

	connectedCallback() {
		this.addEventListener( 'submit', this.#submitHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'submit', this.#submitHandler );
	}

	async renderLocationsList( postalCode: string ) {
		const [
			locations,
			center,
		] = await Promise.all( [
			fetchAllLocations(),
			fetchMapboxLocationLngLat( postalCode ),
		] );

		if ( !locations?.length ) {
			this.setAttribute( 'data-results-count', '0' );

			return;
		}

		if ( !center ) {
			this.setAttribute( 'data-results-count', '0' );

			return;
		}

		const locationGrid = this.querySelector<HTMLElement>( 'div[data-location-results]' );
		if ( !locationGrid ) {
			this.setAttribute( 'data-results-count', '0' );

			return;
		}

		this.setAttribute( 'data-results-count', locations.length.toString() );

		locations.sort( distanceSorter( center.lng, center.lat ) );

		locationGrid.innerHTML = locations.slice( 0, 6 ).map( ( location ) => {
			let link = '';

			if ( location.link_out ) {
				link = `
					<a
						class="arrow-link type-d"
						href="${location.link_out}"
					>
						<span>Meer info &amp; openingsuren op Google Maps</span>
					</a>
				`;
			}

			return `
				<div class="card" data-theme="white">
					<h3>${location.title}</h3>
					<p class="card__info type-d">
						${location.address_lines.join( '<br>' )}
					</p>

					${link}
				</div>
			`;
		} ).join( '' );
	}

	updateLocationIntroTitle( postalCode: string ) {
		const locationTitle = this.querySelector( '[data-locations-results-for-postal-code]' );
		if ( !locationTitle ) {
			return;
		}

		locationTitle.textContent = postalCode;
	}
}

customElements.define( 'mr-locations-by-postal-code', MrLocationsByPostalCode );
